<template>
    <div class="mt-5">
        <section class="container mt-4 mb-2 mb-md-4 mb-lg-5 pt-lg-2 pb-5">

            <div class="row">
                <div class="col-xl-9 col-lg-8">
                    <router-view />    
                </div>

                <BlogSidebar />
                
            </div>
        </section>
      
    </div>
    <button type="button" data-bs-toggle="offcanvas" data-bs-target="#blog-sidebar" aria-controls="blog-sidebar" class="btn btn-sm btn-primary fixed-bottom d-lg-none w-100 rounded-0">
      <i class="bx bx-sidebar fs-xl me-2"></i>
      Sidebar
    </button>
  </template>
  
<script>
import BlogSidebar from "@/components/blog/BlogSidebar.vue";
export default {
    components: {
        BlogSidebar
    },
};
</script>
  