import { createStore } from 'vuex';
import axios from 'axios';

function getCsrfToken() {
  // Retrieve CSRF token from cookies
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith('csrftoken='))
    ?.split('=')[1] || '';
}

export default createStore({
  state: {
    theme: localStorage.getItem('theme') || 'light',
    // isAuthenticated: false,
    
    authToken: localStorage.getItem('authToken') || null,
    user: JSON.parse(localStorage.getItem('user')) || null,

    blogs: [],
    totalBlogs: 0,
    blog: null,
    categories: [],
    tags: [],
  },
  mutations: {
    setTheme(state, theme) {
      state.theme = theme;
      localStorage.setItem('theme', theme);
    },

    // authenticate(state){
    //   const token = localStorage.getItem('authToken');
    // },
    setAuthenticationStatus(state, status) {
      state.isAuthenticated = status;
    },


    setAuthToken(state, token) {
      state.authToken = token;
      localStorage.setItem('authToken', token);
      axios.defaults.headers.common['Authorization'] = `Token ${token}`;  // Set token for all Axios requests
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    clearAuth(state) {
      state.authToken = null;
      state.user = null;
      localStorage.removeItem('authToken');
      localStorage.removeItem('user');
      delete axios.defaults.headers.common['Authorization'];  // Remove the token from Axios headers
    },

    // Blog posts
    SET_BLOGS(state, blogs) {
      state.blogs = blogs;
    },
    APPEND_BLOG(state, blog) {
      state.blogs.push(blog);
    },
    APPEND_BLOGS(state, newBlogs) {
      // state.blogs = [...state.blogs, ...newBlogs];
      newBlogs.forEach((blog) => {
        if (!state.blogs.some((existingBlog) => existingBlog.id === blog.id)) {
          state.blogs.push(blog);
        }
      });
    },
    SET_TOTAL_BLOGS(state, total) {
      state.totalBlogs = total;
    },
    setBlog(state, blog) {
      state.blog = blog;
    },
    // Categories
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    ADD_CATEGORY(state, category) {
      state.categories.push(category);
    },
    UPDATE_CATEGORY(state, updatedCategory) {
      const index = state.categories.findIndex((cat) => cat.id === updatedCategory.id);
      if (index !== -1) {
        state.categories.splice(index, 1, updatedCategory);
      }
    },
    DELETE_CATEGORY(state, categoryId) {
      state.categories = state.categories.filter((cat) => cat.id !== categoryId);
    },
    // Tags
    SET_TAGS(state, tags) {
      state.tags = tags;
    },
    ADD_TAGS(state, newTags) {
      state.tags = [...state.tags, ...newTags];
    },
    updateTag(state, updatedTag) {
      const index = state.tags.findIndex((tag) => tag.id === updatedTag.id);
      if (index !== -1) {
        state.tags.splice(index, 1, updatedTag);
      }
    },
    deleteTag(state, id) {
      state.tags = state.tags.filter(tag => tag.id !== id);
    },
    //
  },
  actions: {
    toggleTheme({ commit, state }) {
      const newTheme = state.theme === 'light' ? 'dark' : 'light';
      commit('setTheme', newTheme);
      document.querySelector('#app').setAttribute('data-bs-theme', newTheme);
    },

    // For authentication
    async login({ commit, dispatch }, credentials) {  // Add `dispatch` here
      try {
        const csrfToken = getCsrfToken();
  
        const response = await axios.post('/api/login/', credentials, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          withCredentials: true,
        });
        console.log('login response:',response);
        
  
        commit('setAuthToken', response.data.token);  // Save token
        await dispatch('fetchUser');  // Fetch user profile after login
      } catch (error) {
        console.error('Login error:', error.response?.data || error.message);
        throw new Error('Login failed');
      }
    },
    async fetchUser({ commit }) {
      try {
        const response = await axios.get('/api/user-profile/');
        commit('setUser', response.data);  // Save user profile to Vuex state
      } catch (error) {
        console.error('Failed to fetch user profile:', error.response?.data || error.message);
        commit('clearAuth');  // Clear token if fetching profile fails
      }
    },
    logout({ commit }) {
      commit('clearAuth');  // Clear token and user data
    },
    async registerUser(_, userData) {
      try {
        const csrfToken = document.cookie
          .split('; ')
          .find((row) => row.startsWith('csrftoken='))
          ?.split('=')[1];
  
        await axios.post('/api/register/', userData, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          withCredentials: true,
        });
      } catch (error) {
        throw new Error('Error during registration');
      }
    },
    async updateUserProfile({ commit }, profile) {
      try {
        const csrfToken = document.cookie
          .split('; ')
          .find((row) => row.startsWith('csrftoken='))
          ?.split('=')[1];
        await axios.put('/api/user-profile/', profile, {
          headers: {
            'X-CSRFToken': csrfToken,
          },
          withCredentials: true,
        });
        commit('setUser', profile);  // Update Vuex state
      } catch (error) {
        throw new Error('Error updating profile');
      }
    },
    async changeUserPassword(_, passwordForm) {
      try {
        const csrfToken = document.cookie
          .split('; ')
          .find((row) => row.startsWith('csrftoken='))
          ?.split('=')[1];
        await axios.post('/api/change-password/', passwordForm, {
          headers: {
            'X-CSRFToken': csrfToken,
          },
          withCredentials: true,
        });
      } catch (error) {
        throw new Error('Error changing password');
      }
    },

    // Blog

    async fetchBlogs({ commit }, {limit, offset}) {
      console.log('fetchBlogs',);
      // console.log('limit', limit);
      // console.log('offset', offset);
      try {
        const response = await axios.get(`/api/posts/?limit=${limit}&offset=${offset}`);
        console.log('response', response);
        // const { posts, total } = response.data;
        const posts = response.data.results;
        const total = response.data.count;


        console.log('response.data', response.data.results);
        console.log('posts', posts);
        console.log('total', total);

        if (offset === 0) {
          commit('SET_BLOGS', posts);
          console.log('set to posts', posts);
        } else {
          commit('APPEND_BLOGS', posts);
          console.log('add to posts', posts);
        }
        commit('SET_TOTAL_BLOGS', total);
      } catch (error) {
        console.error('Error fetching blogs:', error.message);
      }
    },
    

    async fetchBlog({ commit }, id) {
      try {
        const response = await axios.get(`/api/posts/${id}/`);
        commit('APPEND_BLOG', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching blog:', error.message);
        throw error;
      }
    },

    async createBlog({ dispatch }, blogData) {
      console.log('blogData',blogData);
      
      try {
        const csrfToken = getCsrfToken();  // Get CSRF token
    
        const res = await axios.post('/api/posts/', blogData, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,  // Include CSRF token
          },
          withCredentials: true,  // Ensure cookies are sent
        });
    
        dispatch('fetchBlogs');  // Refresh blog list after creation
        return res.data;
      } catch (error) {
        console.error('Error creating blog:', error.response?.data || error.message);
      }
    },
    
    async editBlog({ dispatch }, blogData) {
      console.log('edit blog', blogData);
      
      try {
        const csrfToken = getCsrfToken();  // Get CSRF token
    
        await axios.put(`/api/posts/${blogData.id}/`, blogData, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,  // Include CSRF token
          },
          withCredentials: true,  // Ensure cookies are sent
        });
    
        dispatch('fetchBlogs');  // Refresh blog list after editing
      } catch (error) {
        console.error('Error editing blog:', error.response?.data || error.message);
      }
    },


    // Categories
    async fetchCategories({ commit }) {
      try {
        const response = await axios.get("/api/categories/");
        commit("SET_CATEGORIES", response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async addCategory({ commit }, category) {
      try {
        // const response = 
        await axios.post("/api/categories/", category);
        commit("ADD_CATEGORY", category);
      } catch (error) {
        console.error("Error adding category:", error);
      }
    },
    async updateCategory({ commit }, category) {
      try {
        const response = await axios.put(`/api/categories/${category.id}/`, category);
        commit("UPDATE_CATEGORY", response.data);
      } catch (error) {
        console.error("Error updating category:", error);
      }
    },
    async deleteCategory({ commit }, categoryId) {
      try {
        await axios.delete(`/api/categories/${categoryId}/`);
        commit("DELETE_CATEGORY", categoryId);
      } catch (error) {
        console.error("Error deleting category:", error);
      }
    },
    // Tags
    async fetchTags({ commit }) {
      try {
        const csrfToken = getCsrfToken();
        const response = await axios.get("/api/tags/",{
          'X-CSRFToken': csrfToken,
        });
        commit("SET_TAGS", response.data);
        return response; // Return the response data to be used in the component
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    },
    async createTags({ commit }, newTags) {
      try {
        const csrfToken = getCsrfToken();  // Get the CSRF token
    
        // Send request to the backend to create new tags
        const response = await axios.post("/api/tags/", 
          { tags: newTags },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken,  // Include CSRF token in headers
            },
            withCredentials: true,  // Ensure cookies are sent
          }
        );
    
        // Optionally update the state with the new tags
        commit("ADD_TAGS", response.data);  // Add new tags to state from backend response (if needed)
      } catch (error) {
        console.error("Error creating tags:", error.response?.data || error.message);
      }
    },
    async updateTag({ commit }, tag) {
      const csrfToken = getCsrfToken();
      const response = await fetch(`/api/tags/${tag.id}/`, {
        method: 'PUT',
        body: JSON.stringify(tag),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
      });
      const updatedTag = await response.json();
      commit('updateTag', updatedTag);
    },
    async deleteTag({ commit }, id) {
      await fetch(`/api/tags/${id}/`, { method: 'DELETE' });
      commit('deleteTag', id);
    },


    //
  },
  getters: {
    isAuthenticated: (state) => !!state.authToken,
    getToken: (state) => state.authToken,
    getUser: (state) => state.user,

    blogs: (state) => state.blogs,
    totalBlogs: (state) => state.totalBlogs,
    getCategories: (state) => state.categories,
    getTags: (state) => state.tags,
    
  }
});
