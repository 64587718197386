<template>
  <div
    v-if="!consentAccepted"
    class="position-fixed bottom-0 start-0 mb-4 ms-4 p-4 bg-light border shadow-lg rounded"
    style="max-width: 480px; z-index: 1050"
  >
    <div class="d-flex align-items-center">
      <img src="@/assets/svg/icons/cookie.svg" alt="Cookie Icon" class="me-3" />
      <div>
        <p class="mb-2">
          Our website uses cookies to improve your browsing experience and show relevant information.
          By continuing, you agree to our
          <router-link to="/privacy-policy" class="text-primary text-decoration-underline">
            Cookie Policy & Privacy
          </router-link>.
        </p>
      </div>
    </div>
    <button class="btn btn-primary btn-sm w-100 mt-2" @click="acceptConsent">Accept</button>
  </div>
</template>

<script>
export default {
  name: "CookieConsent",
  data() {
    return {
      consentAccepted: false, // Track if consent is accepted
    };
  },
  mounted() {
    this.checkCookieConsent();
  },
  methods: {
    acceptConsent() {
      localStorage.setItem("cookieConsentAccepted", "true"); // Store consent in localStorage
      this.consentAccepted = true;
    },
    checkCookieConsent() {
      const consent = localStorage.getItem("cookieConsentAccepted");
      if (consent === "true") {
        this.consentAccepted = true;
      }
    },
  },
};
</script>

<style scoped>
img {
  width: 80px;
  height: 80px;
}
</style>
